<template>
  <div class="w-100 fixed">
    <div id="webcam" style="position:relative; display: none;">
      <div class="countdown"></div>
      <video id="player" class="webcamVideo" autoplay playsinline width="100%"></video>
      <canvas style="display: none" id="canvas" width="455" height="607"></canvas>
    </div>
    <Player360
      @tapped="writingDelay()"
      v-if="thePlayer360.display"
      :url="thePlayer360.stream_hls"
      :id="thePlayer360.video_id"
      :cover="thePlayer360.cover"
      :token="loginInfo.token"
      @exit="exit()"
      :options="[]"
      :showOptions="( thePlayer360.display || thePlayer.display ) && rbv"
      @handleVideo="handleVideo"
      :type="thePlayer360.video_type"
      :leftFix="true"
    />
    <Player
      @tapped="writingDelay()"
      v-if="thePlayer.display"
      :url="thePlayer.stream_hls"
      :id="thePlayer.video_id"
      :token="loginInfo.token"
      @exit="exit()"
      :options="[]"
      :showOptions="( thePlayer360.display || thePlayer.display ) && rbv"
      @handleVideo="handleVideo"
      :type="thePlayer.video_type"
    />
  </div>
</template>

<script>
import { intersection, get } from 'lodash';
import { mapState, mapMutations } from 'vuex';
import Player from '@/components/Player.vue';
import Player360 from '@/components/Player360.vue';
// import ReactionStripe from '@/components/ReactionStripe.vue';

export default {
  name: 'MultiVideoWrapper',
  components: {
    Player,
    Player360,
    // ReactionStripe,
  },
  data() {
    return {
      get,
      video: false,
      showVideo360: false,
      showVideo: false,
      rbv: false,
      interval: false,
      writing: false,
      writingTo: false,
    };
  },
  mounted() {
    // const self = this;
    /*
    setTimeout(() => {
      self.startReactionStripe();
    }, 5000);
    */
  },
  methods: {
    ...mapMutations(['loadRedeem', 'openRedeem', 'setVideo', 'setVideo360']),
    exit() {
      this.setVideo360(false);
      this.setVideo(false);
      this.video = false;
      this.showVideo = false;
      this.showVideo360 = false;
    },
    writingDelay() {
      clearTimeout(this.writingTo);
      this.writing = true;
      this.writingTo = setTimeout(() => {
        this.writing = false;
      }, 5000);
    },
    loadVideo(id) {
      const that = this;
      const ep = `/video/${id}`;
      this.api.get(ep, {
        headers: {
          'x-tok-token': get(this.loginInfo, 'token'),
          'Accept-Language': this.locale,
        },
      })
        .then((response) => {
          // eslint-disable-next-line no-debugger
          debugger;
          that.video = response.data;
          if (that.video.video_360 || that.video.title.includes('360')) {
            that.showVideo360 = true;
            that.showVideo = false;
          } else {
            that.showVideo360 = false;
            that.showVideo = true;
          }
          // this.startReactionStripe();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    /*
    startReactionStripe() {
      this.interval = setInterval(() => {
        const isF = isFunction(this.rdsPlayer.userActive);
        if (isF) {
          this.rbv = this.rdsPlayer.userActive();
        } else {
          const hidden = document.querySelector('.bmpui-ui-controlbar.bmpui-hidden');
          this.rbv = Boolean(!hidden);
        }
      }, 250);
      window.start(get(this.loginInfo, 'token'), this.reactionStripeConfig);
      window.initBar();
    },
    */
    // eslint-disable-next-line consistent-return
    handleVideo(video) {
      if (!get(this, 'me.registered')) {
        this.register();
      } else {
        const activeOffers = get(this, 'me.active_offers', []);
        const validArray = intersection(activeOffers, video.offers);
        if (validArray.length > 0) {
          this.loadVideo(video.video_id);
        } else {
          const ro = {
            offers: [...video.offers],
            play: false,
            route: false,
          };
          this.loadRedeem(ro);
        }
        console.log(video);
      }
    },
  },
  computed: {
    ...mapState(['thePlayer360', 'thePlayer', 'me', 'api', 'loginInfo', 'locale', 'reactionStripeConfig', 'rdsPlayer']),
  },
};
</script>

<style lang="scss" scoped>
.Videos {
  display: flex;
  padding: 20px;
  justify-content: center;
  width: 100%;
  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
    padding: 0;
    margin-top: 20px;
  }
}

.VideoCell {
  margin: 20px;
  flex: 0 1 33%;
  @media only screen and (max-width: 600px) {
    flex: 0 1 100%;
    margin: 0;
  }

  &__cover {
    padding-top: 56.25%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
    background-size: cover;
  }

  &__title {
    margin-top: 15px;
    color: white;
    opacity: 0;
  }
}

.playBtn {
  position: absolute;
  top: 0;
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
}
</style>
